@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700;900&display=swap');


.split-screen {
  display: flex;
  height: 100vh;
  position: relative; 
  flex-direction: column;
}

.coltboy-side, .jvp-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 24px;
  height: 50vh;
}

.coltboy-side {
  background-color: white;
  color: #000000;
  font-family: 'Orbitron', sans-serif;
  font-weight: 900;
}

.full-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.content {
  text-align: center;
}

.toggle-button-top {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5vh;
  z-index: 10;
  text-indent: -9999px;
  cursor: pointer;
}

.toggle-button-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5vh;
  z-index: 10;
  text-indent: -9999px;
  cursor: pointer;
}

.home-button-bottom {
  font-size: 20px;
  position: fixed;
  text-align: center;
  bottom: 1%;
  right: 1%;
  z-index: 11;
  color: white;
}

.home-button-top {
  font-size: 20px;
  position: fixed;
  text-align: center;
  top: 1%; 
  right: 1%;
  z-index: 11;
  color: black;
}

.jvp-style {
  background-image: linear-gradient(to right, #00aaff, #46B03A, orange);
  background-size: 100% 20px; /* Adjusts the stripe height and ensures it repeats */
  color: #ffffff;
  right: 0;
  font-family: 'Bangers', cursive;
  text-shadow:
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px  2px 0 #000,
    2px  2px 0 #000;
  font-size: x-large;
}

.coltboy-style {
  background-color: white;
  left: 0;
  font-family: 'Orbitron', sans-serif;
  font-weight: 900;
  font-size: xx-large;
}

.social-links {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.social-links a {
  display: inline-block;
  background-color: purple;
  color: white;
  padding: 52px 52px; /* Further increase padding to make the boxes larger */
  margin: 5px;
  border-radius: 0; /* Set border-radius to 0 to make the boxes square */
  text-decoration: none;
  transition: background-color 0.3s ease;
  flex: 1 0 200px;
  font-size: 36px; /* Further increase font-size to make the icons larger */
}


.social-links a:hover {
  background-color: darkpurple;
}

.social-links a:visited {
  color: white;
}

.social-links-coltboy a {
  background-color: #607d75;
  border-radius: 15px;
}

.social-links-jvp a {
  background-color: #7D3C98;
  border-radius: 15px;
}

/* Shape divider */
.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  cursor: pointer;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 66px;
  margin-top: -1px;
}

.wave .shape-fill {
  fill: #FFFFFF;
}

.bottom, .top {
  position: relative;
}
